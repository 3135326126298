import type { V2_MetaFunction } from "@remix-run/node";

import { useActionData, Form } from "@remix-run/react";

import type { LinksFunction } from "@remix-run/node";

import stylesUrl from "~/styles/index.css";

import { serverAction } from "./route.server";

export const action = serverAction;

export const meta: V2_MetaFunction = () => {
  return [{ title: "Chunkify" }];
};

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesUrl },
];

export default function Index() {
  const actionData = { chunks: [], ...useActionData<typeof action>() };
  return (
    <div style={{ fontFamily: "system-ui, sans-serif", lineHeight: "1.4" }}>
      <h1>Chunkify</h1>
      <Form method="post">
        <div>
          <label>
            Input: <textarea name="input" defaultValue={"A\nB\nC\nDE\nFG\nH"} maxLength={131071}/>
          </label>
        </div>
        <div>
          <label>
            Chunk Length: <input type="number" name="chunkLength" defaultValue={6} min="1" max="131071" />
          </label>
        </div>
        <div>
          <label>
            Fill: <textarea name="fill" defaultValue={"x"} maxLength={131071} />
          </label>
        </div>
        <div>
          <button type="submit" className="button">
            Chunkify
          </button>
        </div>
      </Form>
      <ul>
        {actionData.chunks.map((value, index) => (
          <li key={index}><div><pre>{value}</pre></div></li>
        ))}
      </ul>
    </div>
  );
}